import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { ComponentDemoComponent } from './components/component-demo/component-demo.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthenticationModule } from './authentication/authentication.module';
import { OKTA_CONFIG, OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OnlineStatusModule } from 'ngx-online-status';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../../src/environments/environment';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleSigninButtonModule,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PromptComponent } from './shared/components/prompt/prompt.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Interceptor } from './shared/http.interceptor';
import { ErrorCatchingInterceptor } from './shared/error.interceptors';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';

import { OnBoardUsersFiltersComponent } from './components/on-board-users-filters/on-board-users-filters.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BulkOperationsComponent } from './components/bulk-operations/bulk-operations.component';
import { WizardDialogComponent } from './shared/components/wizard-dialog/wizard-dialog.component';
import { AutoCompleteSearchModule } from './shared/components/auto-complete-search/auto-complete-search/auto-complete-search.module';

import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { ConfirmPopupComponent } from './shared/components/confirm-popup/confirm-popup.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AddObservationComponent } from './components/audit-details/observations/add-observation/add-observation.component';
import { ChatBoxComponent } from './components/chat-box/chat-box.component';
import { PolicyFiltersPopupComponent } from './components/policy-filters-popup/policy-filters-popup.component';
import { SharedModule } from './shared/shared.module';
// import { AddVendorPopupComponent } from './components/vendors/add-vendor-popup/add-vendor-popup.component';
import { MatSliderModule } from '@angular/material/slider';
import { AddAuditRulesComponent } from './components/add-audit-rules/add-audit-rules.component';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewPolicyComponent } from './components/view-policy/view-policy.component';
import { UploadPolicyDraftComponent } from './components/upload-policy-draft/upload-policy-draft.component';
import { PolicyReviewApproveComponent } from './components/policy-review-approve/policy-review-approve.component';
import { ApprovalsFiltersComponent } from './components/approvals-filters/approvals-filters.component';
import { DeselectableDropdownDirective } from './shared/directives/diselectable-dropdown-directive';
import { RemediationFiltersComponent } from './components/remediation-filters/remediation-filters.component';
import { GroupRulesFiltersComponent } from './components/group-rules-filters/group-rules-filters.component';
// import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import { MatChipsModule } from '@angular/material/chips';
import { CronEditorModule } from './components/cron-editor/cron-editor.module';
import { AuditDetailsCommentComponent } from './components/audit-details-comment/audit-details-comment.component';
import { MatMenuModule } from '@angular/material/menu';
import { JobsFilterComponent } from './components/jobs-filter/jobs-filter.component';
import { AddAssetComponent } from './components/add-asset/add-asset.component';
import { AssetFilterComponent } from './components/asset-filter/asset-filter.component';
import { DialogModule } from 'primeng/dialog';
import { RiskFiltersComponent } from './components/risk-filters/risk-filters.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { GroupFilterComponent } from './components/group-filter/group-filter.component';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MarkdownModule } from 'ngx-markdown';
import { ConnectionServiceModule } from 'ng-connection-service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
const matSnackbarDefaultConfig: MatSnackBarConfig = {
  verticalPosition: 'top',
  horizontalPosition: 'right',
};

const oktaAuth = new OktaAuth({
  // localhost
  // issuer: 'https://dev-67488984.okta.com/oauth2/default',
  // clientId: '0oajdt84t1Uh1MWoD5d7',

  // dynamic
  issuer: environment.oktaIssuer,
  clientId: environment.oktaClientID,

  redirectUri: window.location.origin + '/login/callback',
});

const moduleConfig: OktaConfig = { oktaAuth };

@NgModule({
  declarations: [
    AppComponent,
    ComponentDemoComponent,
    PromptComponent,
    OnBoardUsersFiltersComponent,
    BulkOperationsComponent,
    WizardDialogComponent,
    FileUploadComponent,
    ConfirmPopupComponent,
    DeselectableDropdownDirective,
    ChatBoxComponent,
    PolicyFiltersPopupComponent,
    ViewPolicyComponent,
    UploadPolicyDraftComponent,
    PolicyReviewApproveComponent,
    ApprovalsFiltersComponent,

    GroupRulesFiltersComponent,
    RemediationFiltersComponent,
    // ReleaseNotesComponent,
    AuditDetailsCommentComponent,
    JobsFilterComponent,
    AssetFilterComponent,
    GroupFilterComponent,
    // AddAssetComponent,
    RiskFiltersComponent,
    // AddPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AddObservationComponent,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    // AddVendorPopupComponent,
    MatRadioModule,
    FormsModule,
    MatTreeModule,
    AddAuditRulesComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    AuthenticationModule,
    HttpClientModule,
    RecaptchaV3Module,
    MatSidenavModule,
    OnlineStatusModule,
    OktaAuthModule.forRoot(moduleConfig),
    SocialLoginModule,
    GoogleSigninButtonModule,
    NgxSpinnerModule,
    MatDialogModule,
    NgApexchartsModule,
    MatStepperModule,
    MatTableModule,
    MatAutocompleteModule,
    MatTooltipModule,
    AutoCompleteSearchModule,
    SharedModule,
    MatProgressBarModule,
    MatSliderModule,
    BrowserAnimationsModule,
    MatChipsModule,
    CronEditorModule,
    ButtonModule,
    MatMenuModule,
    AutoCompleteModule,
    DialogModule,
    ProgressSpinnerModule,
    ConnectionServiceModule,
    MarkdownModule.forRoot(),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          // clientId: '0f41386b-31f9-4a29-b62b-568a60f7de58', // localhost
          // redirectUri: 'https://dev3.seconize.co',
          // redirectUri: 'http://localhost:4200',
          clientId: environment.azureSSOClientID,
          redirectUri: window.location.origin,
          authority: 'https://login.microsoftonline.com/organizations',
          // authority: 'https://login.microsoftonline.com/9d4ff427-8876-4dcb-a6c0-c332ee8994a3', // Replace with your tenant ID
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true, // Needed for IE11
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['User.Read.All'],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/', ['User.Read.All']],
        ]),
      }
    ),
  ],
  exports: [FormsModule, MatFormFieldModule, MatDividerModule],
  providers: [
    MatDatepickerModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: matSnackbarDefaultConfig,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.g_recaptcha_site_key,
    },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth },
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
